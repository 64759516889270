import styled from 'styled-components';

import border from 'assets/Common/Borders/horizontal.svg';

import vars from 'variables';

const Root = (css) => styled.div`
  .raw-html p.bordered {
    color: ${vars.colors.white};
    line-height: 230%;
    text-align: center;
    background: url(${border}) no-repeat top center;
    background-size: contain;
  }

  ${vars.desktop.mediaQuery} {
    & {
      padding-bottom: 100px;
    }

    .page-header {
      margin-bottom: 100px;
    }

    .raw-html {
      margin: 40px 0;
    }

    .raw-html p {
      margin-bottom: 30px;
      color: ${vars.colors.grey};
    }

    .raw-html p.bordered {
      margin: 140px 0 60px;
      padding: 30px 250px 0;
      font-size: 0.85rem;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      padding-bottom: 50px;
    }

    .page-header {
      margin-bottom: 60px;
    }

    .raw-html {
      margin-top: -5px;
    }

    .raw-html h3 {
      margin: 50px 0 8px;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 140%;
    }

    .raw-html p {
      margin-bottom: 25px;
    }

    .raw-html p.bordered {
      margin-top: 30px;
      padding: 20px 0 0;
    }
  }

  ${css}
`;

export { Root };
