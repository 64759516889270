import React from 'react';
import { Root } from './style';

import FAQs from 'components/Common/FAQs';
import JoinTheCommunities from 'components/Common/JoinTheCommunities';
import PageHeader from 'components/Common/PageHeader';
import RawHTML from 'components/Common/RawHTML';

import vars from 'variables';

const SimplePage = ({pageContext: {page}}) => {
  const html = (page.html || '')
    .replace(/ASSETS/g, vars.assetsPrefix);

  const Page = Root(
    (page.css || '')
      .replace(/ASSETS/g, vars.assetsPrefix)
      .replace(/BLUE/g, vars.colors.blue)
      .replace(/QUERYDESKTOP/g, vars.desktop.mediaQuery)
      .replace(/QUERYMOBILE/g, vars.mobile.mediaQuery)
  );

  return (
    <Page className="body-width">
      <PageHeader page={page}/>
      <RawHTML html={html}/>
      <JoinTheCommunities/>
      {
        page.faqsIntro ?
          <RawHTML className="faqs-intro" html={page.faqsIntro}/>:
          ''
      }
      {
        page.faqs ?
          <FAQs faqs={page.faqs}/> :
          ''
      }
    </Page>
  );
};

export default SimplePage;
